<template>
  <div>
    <general-table
      ref="providerTable"
      :api-url="APIURL"
      :add-type="addType"
      :columns="columns"
      :view-content="viewContent"
      :type="type"
      guard="providers"
    />
    <div>
      <div>
        <b-modal
          id="merchant"
          ref="genmodal"
          hide-footer
          centered
          size="lg"
          no-close-on-backdrop
          title="Provider"
        >
          <add-edit-provider />
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import AddEditProvider from './control-providers/AddEditProvider.vue'

export default {
  components: { GeneralTable, AddEditProvider },
  data() {
    return {
      APIURL: 'providers',
      addType: 'Add Provider',
      addComponentName: 'add-provider',
      editComponent: 'edit-provider',
      viewContent: false,
      type: 'modal',
      columns: [
        { key: 'id', sortable: true },
        { key: 'name', label: 'Name' },
        { key: 'version', label: 'version' },
        { key: 'fees', label: 'fees' },
        { key: 'status', label: 'Active' },
        { key: 'actions' },
      ],
    }
  },
}
</script>

<style>

</style>
