<template>
  <div>
    <validation-observer
      ref="infoRules"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="userForm.name"
                :state="getValidationState(validationContext)"
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Fees"
            rules="required|regex:^([0-9]+)$"
          >
            <b-form-group
              label="Fees"
              label-for="fees"
            >
              <b-form-input
                id="fees"
                v-model.number="userForm.fees"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Fees"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            name="Name"
          >
            <b-form-group
              label="Version"
              label-for="version"
            >
              <b-form-input
                id="version"
                v-model.number="userForm.version"
                trim
                type="number"
                placeholder="Version"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Active"
            label-for="business_type"
          >
            <b-form-checkbox
              v-model="userForm.status"
              checked="true"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Logo"
            label-for="business_type"
          >
            <b-form-file
              accept=".jpg, .png, .gif"
              placeholder="Choose file"
              @change="onChange"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <div class="border rounded p-1">
            <h4 class="mb-1">
              Logo
            </h4>
            <b-media
              no-body
              vertical-align="center"
            >
              <b-media-aside>
                <b-img
                  :src="preview"
                  height="200"
                  width="200"
                />
              </b-media-aside>
            </b-media>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            @click="addMerchant()"
          >
            Save
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import axios from 'axios'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, regex,
} from '@validations'
import provider from '../providers'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  data() {
    return {
      required,
      regex,
      userForm: {},
      merchantType: [],
      merchants: [],
      owners: [],
      id: this.$store.state.generalIds.id,
      newProviderData: {},
      loader: false,
      preview: null,
      image: null,
    }
  },
  setup() {
    const { getValidationState } = formValidation()

    const { userForm } = provider()

    const { formData, setFormData } = handleFormData()

    return {
      getValidationState,
      userForm,
      formData,
      setFormData,
    }
  },
  mounted() {
    this.viewMerchant()
  },
  methods: {
    onChange(e) {
      this.userForm.logo = e.target?.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    closeModal() {
      this.userForm = {
        name: '',
        status: '',
        fees: '',
        version: '',
        logo: '',
      }
      this.$store.commit('generalIds/SET_ID', null)
      this.$bvModal.hide('merchant')
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, search)
      }
    },
    search(loading, search) {
      axios.get(`get-merchants?search=${search}`).then(res => {
        if (search.length >= 2) {
          this.merchants = res.data.data
          this.owners = res.data.data
        }
        loading(false)
      })
    },
    addMerchant() {
      if (this.id) {
        this.loader = true
        this.setNewProviderData()
        this.setFormData(this.newProviderData)
        axios.post(`providers/${this.id}`, this.formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$parent.$parent.$parent.$refs.providerTable.getAllData()
            this.closeModal()
            this.$toasted.show('Added Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            this.loader = true
            this.setFormData(this.userForm)
            axios.post('providers', this.formData).then(res => {
              this.$parent.$parent.$parent.$refs.providerTable.getAllData()
              if (res.status === 200 || res.status === 201) {
                this.closeModal()
                this.$toasted.show('Added Successfully', {
                  position: 'top-center',
                  duration: 3000,
                })
              }
            }).finally(() => {
              this.loader = false
            })
          }
        })
      }
    },
    setNewProviderData() {
      this.newProviderData = {
        name: this.userForm.name,
        status: this.userForm.status,
        fees: this.userForm.fees,
        version: this.userForm.version,
        logo: this.userForm.logo,
        _method: 'PUT',
      }
    },
    viewMerchant() {
      axios.get(`providers/${this.id}`).then(res => {
        this.userForm = res.data?.data
        this.preview = res.data?.data.logo
      })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
