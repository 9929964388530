import { ref } from '@vue/composition-api'

export default function merchan() {
  const userForm = ref({
    name: '',
    status: '',
    fees: '',
    version: '',
    logo: '',
  })

  const resolveActiveUser = role => {
    if (role === true) return 'Active'

    return 'Not Active'
  }

  return {
    userForm,
    resolveActiveUser,
  }
}
